<template>
  <v-card class="px-4 py-4 accent rounded-lg my-order" :class="[dialog ? 'dialog' : '']" style="border: solid 1px #CCAA55!important;overflow: scroll;">
    <slot v-if="dialog" name="tab-header" />
    <slot name="select-sync-type" />
    <slot name="select-sync" />
    <slot name="select-stokist" />
    <slot name="select-buyer" />
    <slot name="select-seller" />
    <div v-if="cartView === 2 && cartSyncType === 1 && !cartSync && !cartBuyerSelected" class="pt-4">
      <v-select
        v-model.number="StockOutReason"
        :items="[{ value: 0, name: 'None' }, { value: 1, name: 'Stock Lost' }, { value: 2, name: 'Stock Damaged/Defective' }]"
        item-text="name"
        :name="Math.random()"
        item-value="value"
        label="Reason"
        outlined
        autocomplete="off"
        dense
        hide-details
        class="rounded-pill mb-4"
      />
    </div>
    <v-card-text
      v-if="step === 1 && (cartView === 0 ? cartBuyerSelected : true)"
      :class="['cart-view-' + cartView, 'cart-sync-type-' + cartSyncType, 'cart-sync-' + cartSync, 'cart-buyer-' + cartBuyerSelected]"
      class="pa-0 my-order-area mt-2"
    >
      <v-card v-if="cartView === 0 || cartView === 1" class="pt-2 transparent">
        <v-select
          v-model="trx_source"
          :items="$store.state.initialTrxSource"
          item-value="id"
          item-text="name"
          label="Order Source ?"
          outlined
          autocomplete="disabled"
          dense
          small
          class="mb-4 rounded-pill"
          hide-details
          @change="trx_admin = 6, SET_REFID()"
        />
        <v-select
          v-if="!parseInt($store.state.user.uid) && trx_source"
          v-model="trx_admin"
          label="Source Admin"
          :items="[{id: 0, name: 'No Admin'}].concat($store.state.user_admin)"
          :item-text="(v) => {
            return v.name + (v.master_name ? ' (' + v.master_name + ')' : '')
          }"
          item-value="id"
          block
          outlined
          dense
          hide-details
          class="mb-4 rounded-pill"
          autocomplete="disabled"
          @change="SET_REFID()"
        />
         <div v-if="trx_source">
          <REFID v-model="TRX_REFID" :with-id="true" :with-date="true" />
          <div class="d-flex fill-width align-center justify-center pt-2 pb-4 grey--text text--darken-2 p-relative" style="z-index: 1;">
            <span class="yellow lighten-4 red--text text--darken-2 rounded-lg py-2 px-4 caption-small font-weight-bold" style="line-height: 100%;">
              {{TRX_REFID.split('/').length === 5 ? TRX_REFID.split('/')[0]+'/'+TRX_REFID.split('/')[3]+'/'+TRX_REFID.split('/')[4] : TRX_REFID.split('/')[0]+'/'+TRX_REFID.split('/')[1]+'/'+TRX_REFID.split('/')[4]+'/'+TRX_REFID.split('/')[5]}}
            </span>
            <v-menu left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :color="TRX_REFID_DATA.find(r => r.refid === TRX_REFID) ? 'red darken-1' : 'green darken-2'"
                  small
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-information
                </v-icon>
              </template>

              <div v-if="!TRX_REFID_DATA.length" class="py-2 px-4 caption-small">
                No history
              </div>
              <v-list v-else dense>
                <v-list-item
                  v-for="(item, i) in TRX_REFID_DATA"
                  :key="'trx-refid-' + i"
                  class="py-1"
                  style="min-height: 20px;"
                >
                  <v-list-item-title class="d-flex fill-width justify-space-between align-center caption-small">
                    <span :class="[item.refid === TRX_REFID ? 'red--text' : '']" class="font-weight-bold">
                      {{ item.refid }}
                    </span>
                    <span class="pl-4">
                      TRX #{{ item.id }}
                    </span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-card>
      <!-- <v-card v-if="cartView === 0 || cartView === 1" class="pt-2 accent">
        <Currency
          v-model.number="PackageDiscount"
          label="Set Discount Package"
          prefix="Rp"
        />
      </v-card> -->
      <div v-if="parseInt(cartView) === 2 && !cartBuyerSelected && cartSyncType === 0" class="pt-1">
        <v-autocomplete
          v-model.number="VendorSelected"
          :items="$store.state.vendor.data"
          :name="Math.random()"
          item-value="id"
          item-text="name"
          outlined
          autocomplete="off"
          dense
          hide-details
          class="append-0 rounded-xl"
          label="Select Vendor"
        >
          <template v-slot:append-outer>
            <v-btn color="teal darken-2" depressed dark class="rounded-xl ma-0" @click.prevent="VENDOR_OPEN()">
              <v-icon small>
                mdi-store-plus
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:item="data">
            <v-card flat style="background-color: transparent;width: 100%;box-shadow: none!important;">
              <div class="d-flex f-width align-center justify-space-between font-weight-bold text-capitalize py-1" style="font-size: 12px;">
                {{ data.item.name }}
              </div>
            </v-card>
          </template>
        </v-autocomplete>
        <v-row no-gutters class="ref-id-selector">
          <v-col cols="12" class="py-3">
            <v-menu
              v-model="createdMenu"
              :close-on-content-click="false"
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="computedDateFormatted"
                  label="Created"
                  hide-details
                  outlined
                  dense
                  persistent-hint
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="createdDate"
                no-title
                @input="createdMenu = false, GENERATE_REFID()"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>
      <v-card v-if="!cart.length" class="text-center accent grey--text caption pa-4">
        no product here
      </v-card>
      <v-card v-for="(c, iCart) in GetCart" :key="'cart-' + iCart" :outlined="$vuetify.theme.dark" :class="[$vuetify.theme.dark ? 'mb-2' : '']" class="accent my-order-list">
        <v-row no-gutters class="px-3 card-row">
          <v-col cols="3" class="col-md-3 py-3">
            <v-img :src="$getImageAll(c.product_galleries)[0]" class="grey rounded" width="50" height="50" />
          </v-col>
          <v-col cols="9" class="col-md-9 d-flex pt-3 pl-2 caption my-order-list-title">
            <div class="d-flex fill-width justify-space-between align-center">
              {{ c.product_name }}
              <v-btn v-if="!parseInt(c.sync)" depressed x-small class="rounded-pill text-capitalize caption-small" @click.prevent="ResetPrice(c, iCart)">
                <v-icon x-small class="mr-1">
                  mdi-refresh
                </v-icon>
                Reset Price
              </v-btn>
            </div>
            <v-divider class="my-2" />
            <div v-if="cartView === 0 && cartBuyerSelected" class="d-flex fill-width justify-space-between align-start">
              <div class="d-flex align-center">
                <input
                  v-model.number="GetCart[iCart].customDiscount"
                  type="number"
                  style="max-width: 45px;outline:none;"
                  @keyup="SetCustomDiscountItem(iCart, c.id, true)"
                  @change="SetCustomDiscountItem(iCart, c.id, true)"
                >%
              </div>
              <div v-if="c.customDiscount <= 100" class="d-flex flex-column align-end" style="max-width:120px;">
                <span class="d-inline-block pr-1">
                  <Currency
                    v-model.number="GetCart[iCart].price_discount"
                    class-add="mb-0"
                    @keyup="SetCustomDiscountItem(iCart, c.id, false)"
                    @change="SetCustomDiscountItem(iCart, c.id, false)"
                  />
                  <!-- {{ c.price_discount | price }} -->
                </span>
                <small class="red--text text-decoration-line-through font-weight-medium pr-1">
                  {{ parseInt(c.label_price) | price }}
                </small>
              </div>
              <div v-else>
                {{ parseInt(c.label_price) | price }}
              </div>
              <!-- <span v-if="$BuyPrice(c, cartBuyerSelected.role) > 0 && $BuyPrice(c, cartBuyerSelected.role) < parseInt(c.label_price)">
                <span class="d-inline-block pr-1">
                  {{ $BuyPrice(c, cartBuyerSelected.role) | price }}
                </span>
                <small class="red--text text-decoration-line-through font-weight-medium">
                  {{ parseInt(c.label_price) | price }}
                </small>
              </span>
              <span v-else>
                {{ parseInt(c.label_price) | price }}
              </span> -->
            </div>
            <div v-else-if="cartView < 2" class="price">
              <span v-if="c.price_discount > 0 && (c.price_discount < parseInt(c.label_price))">
                <span class="d-inline-block pr-1">
                  {{ c.price_discount | price }}
                </span>
                <small class="red--text text-decoration-line-through font-weight-medium">
                  {{ parseInt(c.label_price) | price }}
                </small>
              </span>
              <span v-else>
                {{ parseInt(c.label_price) | price }}
              </span>
              <!-- {{ $BuyPrice(c, $store.state.user.role) | price }} -->
            </div>
          </v-col>
        </v-row>
        <div class="text-right pr-2 pb-3 card-act">
          <div class="list-quantity mr-1">
            <v-btn x-small fab depressed color="transparent" @click.prevent="parseInt(GetCart[iCart].quantity) > 1 ? CART_UPDATE('minus', c) : false">
              <v-icon class="orange--text caption">
                mdi-minus
              </v-icon>
            </v-btn>
            <input v-model.number="GetCart[iCart].quantity" type="text" class="qty-input" @keypress="$NumberOnly" @keyup="CART_UPDATE('quantity', c)" @blur="!parseInt(c.quantity) ? GetCart[iCart].quantity = 1 : ''">
            <v-btn x-small fab depressed color="transparent" @click.prevent="CART_UPDATE('plus', c)">
              <v-icon class="orange--text caption">
                mdi-plus
              </v-icon>
            </v-btn>
          </div>
          <v-btn x-small fab depressed color="transparent" @click.prevent="CART_UPDATE('delete', c)">
            <v-icon class="red--text body-2">
              mdi-delete
            </v-icon>
          </v-btn>
        </div>
        <v-divider />
      </v-card>
    </v-card-text>
    <v-card-text v-else-if="step === 2" :class="['cart-view-' + cartView, 'cart-sync-type-' + cartSyncType]" class="px-0 pa-0 accent my-order-area mt-2">
      <div v-if="cartView !== 2" class="pb-2">
        <!-- <div @drop="drop($event)" @dragover="dropAllow($event)"> -->
        <div>
          <v-expansion-panels flat outlined active-class="grey lighten-2">
            <v-expansion-panel v-if="GetCartMerchandise.length">
              <v-expansion-panel-header class="py-0 caption font-weight-bold">
                Merchandise ({{ GetCartMerchandise.length }}) :
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ProductSearch :is-merchandise="true" placeholder="Search Merchandise" @input="CART_MERCHANDISE_ADDITIONAL_PROCESS('merchandise', $event)" />
                <div v-for="(c, iCart) in GetCartMerchandise" :key="'cart-merchandise-' + iCart" class="d-flex fill-width justify-space-between align-center white rounded-pill py-1 px-2 my-1">
                  <span class="caption d-inline-flex" style="letter-spacing:0px!important;line-height:120%;">{{ c.product_name }}</span>
                  <div class="d-flex align-center" style="max-width: 75px;">
                    <input
                      v-model.number="GetCartMerchandise[iCart].quantity"
                      type="number"
                      style="max-width: 35px;outline:none;border-radius:4px;background:#f0f0f0;padding-left:5px;font-size:12px;"
                      class="mr-1"
                      @keypress="$NumberOnly"
                      @keyup="updatedCartMerchandiseAdditionalQuantity(c.id, c.quantity)"
                      @change="updatedCartMerchandiseAdditionalQuantity(c.id, c.quantity)"
                    >
                    <v-btn x-small fab depressed color="transparent" @click.prevent="CART_UPDATE('delete', c)">
                      <v-icon class="red--text body-2">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </div>
                  <!-- <span class="d-flex" style="min-width:30px;">
                    {{ c.quantity }}x
                  </span> -->
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-else>
              <v-expansion-panel-header class="py-0 caption font-weight-bold">
                Merchandise ({{ GetCartMerchandise.length }}) :
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ProductSearch :is-merchandise="true" placeholder="Search Merchandise" @input="CART_MERCHANDISE_ADDITIONAL_PROCESS('merchandise', $event)" />
                <div class="red--text caption text-center pt-2">
                  No merchandise
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <v-divider />
        <div>
          <v-expansion-panels flat outlined active-class="grey lighten-2">
            <v-expansion-panel v-if="GetCartAdditional.length">
              <v-expansion-panel-header class="py-0 caption font-weight-bold">
                Additional Packaging ({{ GetCartAdditional.length }}) :
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ProductSearch :is-additional="true" placeholder="Search Packaging" @input="CART_MERCHANDISE_ADDITIONAL_PROCESS('additional', $event)" />
                <div v-for="(c, iCart) in GetCartAdditional" :key="'cart-merchandise-' + iCart" class="d-flex fill-width justify-space-between align-center white rounded-pill py-1 px-2 my-1">
                  <span class="caption d-inline-flex" style="letter-spacing:0px!important;line-height:120%;">{{ c.product_name }}</span>
                  <div class="d-flex align-center" style="max-width: 75px;">
                    <input
                      v-model.number="GetCartAdditional[iCart].quantity"
                      type="number"
                      style="max-width: 35px;outline:none;border-radius:4px;background:#f0f0f0;padding-left:5px;font-size:12px;"
                      class="mr-1"
                      @keypress="$NumberOnly"
                      @keyup="updatedCartMerchandiseAdditionalQuantity(c.id, c.quantity)"
                      @change="updatedCartMerchandiseAdditionalQuantity(c.id, c.quantity)"
                    >
                    <v-btn x-small fab depressed color="transparent" @click.prevent="CART_UPDATE('delete', c)">
                      <v-icon class="red--text body-2">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </div>
                  <!-- <span class="d-flex" style="min-width:30px;">
                    {{ c.quantity }}x
                  </span> -->
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-else>
              <v-expansion-panel-header class="py-0 caption font-weight-bold">
                Additional Packaging ({{ GetCartAdditional.length }}) :
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ProductSearch :is-additional="true" placeholder="Search Packaging" @input="CART_MERCHANDISE_ADDITIONAL_PROCESS('additional', $event)" />
                <div class="red--text caption text-center pt-2">
                  No item
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div class="pb-4">
          <v-divider />
        </div>
        <v-text-field
          v-model="BookingCode"
          :name="Math.random()"
          label="Ex. Booking Code"
          dense
          outlined
          hide-details
          autocomplete="disabled"
          class="mt-2"
        />
        <v-text-field
          v-model="BookingCode2"
          :name="Math.random()"
          label="Ex. Order ID"
          dense
          outlined
          hide-details
          autocomplete="disabled"
          class="mt-2"
        />
      </div>
      <v-divider class="my-3" />
      <div class="py-2">
        <v-select
          v-model.number="ShippingMethod"
          :name="Math.random()"
          :items="[
            { value: 0, name: 'Without Address' },
            { value: 1, name: 'With Address' }
          ]"
          item-text="name"
          autocomplete="off"
          item-value="value"
          label="Shipping Method"
          outlined
          dense
          hide-details
          class="required mb-2"
        />
      </div>
      <div v-if="!parseInt(ShippingMethod)">
        <v-autocomplete
          v-model.number="WithoutAddressSelectedKecamatan"
          :items="area"
          :name="Math.random()"
          :search-input.sync="areaquery"
          item-value="kecamatan_id"
          item-text="area_name"
          autocomplete="disabled"
          outlined
          dense
          hide-details
          class="mb-4 required"
          label="District, City, Province"
        ></v-autocomplete>
      </div>
      <div v-else>
        <div class="d-flex caption payment-shipping-title">
          Ship To :
          <v-spacer />
          <v-btn text x-small :color="$vuetify.theme.dark ? 'grey lighten-2' : 'grey darken-2'" class="text-capitalize" @click.prevent="OpenAddress()">
            <v-icon class="mr-1 body-2">
              mdi-plus
            </v-icon>
            Add New
          </v-btn>
        </div>
        <v-card v-if="addressSelected" class="accent no-shadow">
          <v-card-text class="px-3 pt-2 body-1 text-md-caption">
            <v-btn icon x-small class="float-right" @click.prevent="OpenAddress(addressSelected)">
              <v-icon class="body-1">
                mdi-pencil
              </v-icon>
            </v-btn>
            <strong>{{ addressSelected.name }}</strong><br>
            {{ addressSelected.address }}
            <div class="body-1 text-md-caption">
              {{ $phone(addressSelected.phone) }}
            </div>
            <div class="body-2 text-md-caption text-right">
              {{ addressSelected.kecamatan_name }}, {{ addressSelected.city_name }}<br>{{ addressSelected.province_name }}
            </div>
          </v-card-text>
        </v-card>
        <div class="pb-3 text-center">
          <v-btn depressed x-small color="grey lighten-2" light class="mt-2 text-capitalize" @click.prevent="selectAddress = true">
            {{ addressSelected ? 'Change Address' : 'Select Address' }}
          </v-btn>
          <v-divider class="mt-4" />
        </div>
      </div>
      <v-card v-if="(parseInt(ShippingMethod) ? addressSelected : WithoutAddressSelectedKecamatan) && cartView !== 2" class="accent no-shadow pb-4">
        <v-card-text class="px-0 pt-2 pb-2 body-1 text-md-caption">
          <v-select
            v-model="isDropship.show"
            :name="Math.random()"
            :items="[
              { value: true, name: 'Yes' },
              { value: false, name: 'None' }
            ]"
            item-text="name"
            item-value="value"
            label="Dropship ?"
            autocomplete="disabled"
            dense
            small
            hide-details
          />
        </v-card-text>
        <v-card-text v-if="isDropship.show" class="px-0 pt-2 pb-2 body-1 text-md-caption px-3 rounded">
          <v-text-field
            v-model="isDropship.dropship_name"
            label="Sender Name"
            outlined
            dense
            :name="Math.random()"
            hide-details
            autocomplete="disabled"
            class="mb-4"
          />
          <v-text-field
            v-model="isDropship.dropship_phone"
            label="Sender Phone"
            outlined
            dense
            :name="Math.random()"
            hide-details
            autocomplete="disabled"
          />
        </v-card-text>
      </v-card>
      <v-card v-if="(parseInt(ShippingMethod) ? addressSelected : WithoutAddressSelectedKecamatan)" class="accent no-shadow">
        <!-- <v-card-text v-if="false" class="px-0 pt-2 pb-2 body-1 text-md-caption">
          <v-select
            v-model.number="originSelected"
            :name="Math.random()"
            :items="originList"
            item-text="name"
            item-value="value"
            label="Set Shipping Origin"
            autocomplete="disabled"
            dense
            small
            hide-details
            :append-outer-icon="$store.state.user.role === 'pusat' ? (originList.find(r => parseInt(r.value) === parseInt(originSelected)) ? originList.find(r => parseInt(r.value) === parseInt(originSelected)).id ? 'mdi-pencil' : 'mdi-plus' : 'mdi-plus') : ''"
            @click:append-outer="$store.state.user.role === 'pusat' ? OpenOrigin(originSelected) : false"
          />
        </v-card-text> -->
        <v-card-text class="px-0 pt-2 pb-2 body-1 text-md-caption">
          <v-select
            v-model="courierSelected"
            :name="Math.random()"
            :items="itemsCouriers.filter(r => r.show)"
            item-text="name"
            item-value="value"
            label="Select Courier"
            autocomplete="disabled"
            dense
            small
            hide-details
          />
        </v-card-text>
        <v-card-text v-if="courierSelected && courierSelected !== 'cod'" class="px-0 pt-0 body-1 text-md-caption d-flex fill-width justify-space-between">
          <select v-model.number="shippingSelected" class="shipping-list-select green white--text caption-small font-weight-bold" style="max-width: 125px!important;">
            <option v-for="(s, iS) in shippingList" :key="'shipping-' + iS" :value="parseInt(s.id)">
              {{ s.service_name }} ({{ $totalWeight(cart) }}kg)
            </option>
          </select>
          <div class="pl-2">
            <!-- {{ countShippingCost | price }} -->
            <Currency
              v-model.number="ShippingCustom"
              label="Shipping Cost"
              class-add="mb-0"
            />
          </div>
        </v-card-text>
      </v-card>
      <div v-if="cartView !== 2">
        <Currency
          v-model.number="priceDiscountTreatCustomer"
          label="Treat Customer (Discount)"
          class-add="mb-0 mt-2 red lighten-5"
        />
      </div>
      <div v-if="cartView !== 2" class="d-flex caption payment-shipping-title pt-2 pb-1 justify-space-between">
        Payment :
        <!-- <div v-if="parseInt(UserDeposit)" class="d-flex align-center">
          Deposit
          <v-chip x-small color="green" dark class="font-weight-bold ml-2">
            {{ UserDeposit | price }}
          </v-chip>
        </div> -->
      </div>
      <!-- <div v-if="cartView !== 2 && parseInt(UserDeposit)">
        <Currency
          v-model.number="PaidDeposit"
          label="Pay with Deposit"
          class-add="mb-0 mt-2"
        />
      </div> -->
      <div v-if="cartView !== 2">
        <Currency
          v-model.number="PaidAmount"
          label="Paid Amount"
          class-add="mb-0 mt-2"
        />
      </div>
      <div v-if="cartView !== 2" class="pt-2">
        <v-autocomplete
          v-model="paymentSelected"
          :items="SellerBanks"
          item-text="bank_name"
          return-object
          :name="Math.random()"
          outlined
          dense
          autocomplete="disabled"
          hide-details
          label="Select Bank"
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.bank_name }} - {{ data.item.bank_account_name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-chip x-small class="mr-1">
                    {{ data.item.bank_account_number }}
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </div>
      <v-card v-if="paymentSelected && cartView !== 2" class="accent no-shadow">
        <v-card-text class="px-3 pt-2 body-1 text-md-body-2">
          {{ paymentSelected.bank_name }} - {{ paymentSelected.bank_account_name }}
          <div class="body-1 text-md-body-2">
            {{ paymentSelected.bank_account_number }}
          </div>
        </v-card-text>
      </v-card>
      <div v-if="paymentSelected" class="pt-2 pb-4 no-shadow">
        <v-text-field
          v-model="checkoutNotes"
          label="Notes"
          outlined
          dense
          :name="Math.random()"
          hide-details
          autocomplete="disabled"
        />
      </div>
      <!-- <div class="py-4">
        <v-menu
          v-model="createdMenu"
          :close-on-content-click="false"
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFormatted"
              label="Created Date"
              hide-details
              outlined
              dense
              persistent-hint
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="createdDate"
            no-title
            @input="createdMenu = false"
          ></v-date-picker>
        </v-menu>
      </div> -->
    </v-card-text>

    <!-- SUBTOTAL WITH DISCOUNT -->
    <div v-if="cartView === 2 || !cart.length" class="text-center pt-2" v-html="'&nbsp;'" />
    <div v-else-if="cartView === 0 && cartBuyerSelected" class="d-flex flex-column text-right pt-2 cart-total-price">
      <!-- SELL -->
      <v-divider />
      <div class="caption-small pt-1">
        Subtotal : {{ cartSubTotal | price }}
      </div>
      <!-- {{ GetDiscount }} -->
      <div class="py-2 my-1 grey lighten-4 pl-2 overflow-auto" style="height: 10vh">
        <div class="d-flex caption-small fill-width align-center justify-space-between">
          Discount Customer
          <div class="d-flex align-center">
            <v-chip x-small class="mx-1">
              <v-icon class="caption mr-1">
                mdi-label-multiple-outline
              </v-icon>
              <input
                v-model.number="discountCustomerCheckout.percent"
                type="number"
                style="max-width: 35px;outline:none;"
                @keyup="SetCustomDiscountCustomer(true)"
                @change="SetCustomDiscountCustomer(true)"
              >%
            </v-chip>:
            <div class="pl-1" style="max-width:120px;">
              <Currency
                v-model.number="discountCustomerCheckout.value"
                class-add="mb-0"
                @keyup="SetCustomDiscountCustomer(false)"
                @change="SetCustomDiscountCustomer(false)"
              />
            </div>
          </div>
        </div>
        <v-divider class="my-1" />
        <div class="d-flex caption-small fill-width align-center justify-space-between">
          Discount Package
          <div class="d-flex align-center">
            <v-chip x-small class="mx-1">
              <v-icon class="caption mr-1">
                mdi-label-multiple-outline
              </v-icon>
              <input
                v-model.number="discountPackageCheckout.percent"
                type="number"
                style="max-width: 35px;outline:none;"
                @keyup="SetCustomDiscountPackage(true)"
                @change="SetCustomDiscountPackage(true)"
              >%
            </v-chip>:
            <div class="pl-1" style="max-width:120px;">
              <Currency
                v-model.number="discountPackageCheckout.value"
                class-add="mb-0"
                @keyup="SetCustomDiscountPackage(false)"
                @change="SetCustomDiscountPackage(false)"
              />
            </div>
          </div>
        </div>
      </div>
      <span v-if="discountCustomerCheckout.value || discountPackageCheckout.value || ShippingCustom">
        Total : {{ ((cartSubTotal - (discountCustomerCheckout.value || 0) - (discountPackageCheckout.value || 0)) + ShippingCustom - priceDiscountTreatCustomer) | price }}
      </span>
      <span v-else>
        Total  : {{ (cartSubTotal - (discountCustomerCheckout.value || 0) - (discountPackageCheckout.value || 0) - priceDiscountTreatCustomer) | price }}
      </span>
    </div>
    <div v-else-if="(cartView === 0 ? cartBuyerSelected : true)" class="d-flex flex-column text-right pt-2 cart-total-price">
      <!-- BUY -->
      <v-divider />
      <div class="caption-small pt-1">
        Subtotal : {{ cartSubTotal | price }}
      </div>
      <!-- {{ GetDiscount }} -->
      <div class="py-2 my-1 grey lighten-4 pl-2">
        <div class="d-flex caption-small fill-width align-center justify-space-between">
          Discount
          <div class="d-flex align-center">
            <v-chip x-small class="mx-1">
              <v-icon class="caption mr-1">
                mdi-label-multiple-outline
              </v-icon>
              <input
                v-model.number="discountGlobalCheckout.percent"
                type="number"
                style="max-width: 35px;outline:none;"
                @keyup="SetCustomDiscountGlobal(true)"
                @change="SetCustomDiscountGlobal(true)"
              >%
            </v-chip>:
            <div class="pl-1" style="max-width:120px;">
              <Currency
                v-model.number="discountGlobalCheckout.value"
                class-add="mb-0"
                @keyup="SetCustomDiscountGlobal(false)"
                @change="SetCustomDiscountGlobal(false)"
              />
            </div>
          </div>
        </div>
      </div>
      <span>
        Total : {{ (CountTotal - (priceDiscountTreatCustomer || 0)) | price }}
      </span>
      <!-- <span v-else>
        Total : {{ (CountTotal || 0)) | price }}
      </span> -->
    </div>
    <v-card-actions v-if="cartView === 2" class="px-0 pt-1">
      <v-btn v-if="dialog" small outlined class="text-capitalize pb-1" color="red" @click.prevent="$emit('close-dialog', true)">
        Close
      </v-btn>
      <v-spacer v-if="dialog" />
      <!-- <v-btn v-if="!cartBuyerSelected" depressed class="flex-grow-1 rounded-xl text-capitalize" color="grey lighten-2" light :small="!dialog" :disabled="!cart.length" @click.prevent="CheckoutSyncInventory()">
        Process Stock
      </v-btn>
      <v-btn v-else-if="step < 2" :class="[ dialog ? '' : 'flex-grow-1']" :block="!dialog && step === 1" :small="!dialog" color="grey lighten-2" light depressed class="rounded-xl text-capitalize" :disabled="!cart.length" @click.prevent="step = 2">
        Next
      </v-btn> -->
      <v-btn
        depressed
        class="flex-grow-1 rounded-xl text-capitalize"
        color="grey lighten-2"
        light
        :small="!dialog"
        :disabled="!cart.length || (!cartSyncType && !VendorSelected) || (cartSyncType === 1 && cartSync === 1 && !cartBuyerSelected)"
        @click.prevent="CheckoutSyncInventory()"
      >
        {{ cartSyncType ? 'Process' : 'Buy' }} Stock
      </v-btn>
    </v-card-actions>
    <v-card-actions v-else class="px-0 pt-0">
      <v-btn v-if="step !== 1 || (step === 1 && dialog)" :small="!dialog" text color="red" class="text-capitalize" @click.prevent="step === 2 ? step = 1 : $emit('close-dialog', true)">
        {{ step === 2 ? 'Back' : 'Close' }}
      </v-btn>
      <v-spacer />
      <!-- <v-btn v-if="step < 2 && (trx_source === 'WA' ? parseInt(trx_admin) : trx_source)" :class="[ dialog ? '' : 'flex-grow-1']" :block="!dialog && step === 1" :small="!dialog" color="grey lighten-2" light depressed class="rounded-xl text-capitalize" :disabled="!cart.length || (cartView === 0 ? !cartBuyerSelected : false)" @click.prevent="step = 2"> -->
      <v-btn v-if="step < 2 && trx_source" :class="[ dialog ? '' : 'flex-grow-1']" :block="!dialog && step === 1" :small="!dialog" color="grey lighten-2" light depressed class="rounded-xl text-capitalize" :disabled="!cart.length || (cartView === 0 ? !cartBuyerSelected : false)" @click.prevent="step = 2">
        Next
      </v-btn>
      <v-btn v-else-if="step >= 2" depressed class="flex-grow-1 text-capitalize" color="primary--text grey lighten-2" :small="!dialog" :disabled="!cart.length || loadingShipping" @click.prevent="CHECKOUT()">
       Checkout
      </v-btn>
    </v-card-actions>
    <v-dialog
      v-model="dialogVendor.show"
      max-width="450"
      scrollable
    >
      <v-card v-if="dialogVendor.data">
        <v-card-title
          class="primary white--text py-3 subtitle-1"
        >
          {{ parseInt(dialogVendor.data.id) ? 'Update Vendor' : 'Create New Vendor' }}
        </v-card-title>
        <v-card-text>
          <div class="px-0 pt-8 pb-0">
            <v-text-field
              v-model="dialogVendor.data.name"
              label="Name"
              outlined
              dense
              :name="Math.random()"
              hide-details
              autocomplete="disabled"
              class="mb-4 required"
            />
            <v-text-field
              v-model="dialogVendor.data.phone"
              label="Phone"
              outlined
              :name="Math.random()"
              dense
              hide-details
              autocomplete="disabled"
              class="mb-4 required"
              @keypress="$NumberOnly"
            />
            <v-textarea
              v-model="dialogVendor.data.address"
              rows="2"
              :name="Math.random()"
              label="Address"
              outlined
              dense
              autocomplete="disabled"
              hide-details
              class="required"
            />
          </div>
        </v-card-text>
        <v-card-actions class="px-6">
          <v-btn outlined small color="red" class="text-capitalize" @click.prevent="dialogVendor.show = false">
            Close
          </v-btn>
          <v-spacer />
          <v-btn depressed small color="primary" class="text-capitalize" @click.prevent="VENDOR_PROCESS()">
            {{ dialogVendor.data.id ? 'Update Vendor' : 'Create Vendor' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogAddress.show"
      max-width="450"
      scrollable
    >
      <v-card v-if="dialogAddress.data">
        <v-card-title
          class="primary white--text py-3 subtitle-1"
        >
          {{ dialogAddress.data.id ? 'Update Address' : 'Create New Address' }}
        </v-card-title>
        <v-card-text>
          <div class="px-0 pt-8 pb-0">
            <v-text-field
              v-model="dialogAddress.data.name"
              label="Name"
              outlined
              dense
              :name="Math.random()"
              hide-details
              autocomplete="disabled"
              class="mb-4 required"
            />
            <v-text-field
              v-model="dialogAddress.data.phone"
              label="Phone"
              outlined
              :name="Math.random()"
              dense
              hide-details
              autocomplete="disabled"
              class="mb-4 required"
              @keypress="$NumberOnly"
            />
            <v-autocomplete
              v-model.number="dialogAddress.data.area"
              :items="area"
              :name="Math.random()"
              :search-input.sync="areaquery"
              item-value="id"
              item-text="area_name"
              autocomplete="disabled"
              outlined
              dense
              hide-details
              class="mb-4 required"
              label="District, City, Province"
            ></v-autocomplete>
            <v-textarea
              v-model="dialogAddress.data.address"
              rows="2"
              :name="Math.random()"
              label="Full Address"
              outlined
              dense
              autocomplete="disabled"
              hide-details
              class="required"
            />
          </div>
        </v-card-text>
        <v-card-actions class="px-6">
          <v-btn outlined small color="red" class="text-capitalize" @click.prevent="dialogAddress.show = false">
            Close
          </v-btn>
          <v-spacer />
          <v-btn depressed small color="primary" class="text-capitalize" @click.prevent="ProcessAddress()">
            {{ dialogAddress.data.id ? 'Update Address' : 'Add Address' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogOrigin.show"
      max-width="450"
      scrollable
    >
      <v-card v-if="dialogOrigin.data" class="accent">
        <v-card-title
          class="primary white--text py-3 subtitle-1"
        >
          {{ dialogOrigin.data.id ? 'Update Shipping Origin' : 'Create New Shipping Origin' }}
        </v-card-title>
        <v-card-text>
          <div class="px-0 pt-8 pb-0">
            <v-autocomplete
              v-model.number="dialogOrigin.data.kecamatan_id"
              :items="area"
              :name="Math.random()"
              :search-input.sync="areaquery"
              item-value="kecamatan_id"
              item-text="area_name"
              autocomplete="disabled"
              outlined
              dense
              hide-details
              class="mb-4 required"
              label="District, City, Province"
            ></v-autocomplete>
          </div>
          <div class="px-0 pb-0">
            <v-select
              v-model.number="dialogOrigin.data.status"
              :name="Math.random()"
              :items="[
                { value: 1, name: 'Active' },
                { value: 0, name: 'Not Active' },
                { value: 100, name: 'Remove' }
              ]"
              item-text="name"
              autocomplete="off"
              item-value="value"
              label="Status"
              outlined
              dense
              hide-details
              class="required"
            />
          </div>
        </v-card-text>
        <v-card-actions class="px-6">
          <v-btn outlined small color="red" class="text-capitalize" @click.prevent="dialogOrigin.show = false">
            Close
          </v-btn>
          <v-spacer />
          <v-btn depressed small color="green" class="text-capitalize" @click.prevent="ProcessOrigin()">
            {{ dialogOrigin.data.id ? 'Update' : 'Create' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="selectAddress"
      scrollable
      max-width="300px"
    >
      <v-card class="accent">
        <v-card-title class="subtitle-1 pt-2 px-4">
          Select Address :
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-2" style="height: 300px;">
          <v-card
            v-for="(addr, iAddr) in MyAddress"
            :key="'address-' + iAddr"
            class="py-1 px-3 accent select-address"
            outlined
            @click.prevent="addressSelected = Object.assign({}, addr), selectAddress = false"
          >
            <div class="body-2 font-weight-bold">
              {{ addr.name }}
            </div>
            <div class="caption">
              {{ $phone(addr.phone) }}
            </div>
            <div class="caption">
              {{ addr.address }}
            </div>
            <div class="caption-small mb-2 text-right font-italic">
              {{ addr.kecamatan_name }}, {{ addr.city_name }}
            </div>
            <v-divider />
          </v-card>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            x-small
            text
            class="text-capitalize"
            @click="selectAddress = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
let searchTimeout = null
const dialogOrigin = {
  id: null,
  city_id: 0,
  city_type: '',
  city_name: '',
  kecamatan_id: 0,
  kecamatan_name: '',
  status_message: '',
  url: '',
  latlong: '',
  status: 1
}
export default {
  props: {
    cartView: {
      type: Number,
      default: 0
    },
    cartBuyerSelected: {
      type: Object,
      default: () => null
    },
    myGlobalPrice: {
      type: Array,
      default: () => []
    },
    cartSyncType: {
      type: Number,
      default: 0
    },
    cartSync: {
      type: Number,
      default: 0
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data: (vm) => ({
    selectAddress: false,
    createdDate: vm.$localDT(new Date(), 'datedefault'),
    dateFormatted: vm.formatDate(vm.$localDT(new Date(), 'datedefault')),
    createdMenu: false,
    isDropship: {
      show: false,
      dropship_name: '',
      dropship_phone: '',
      dropship_notes: ''
    },
    loadingShipping: false,
    step: 1,
    cart: [],
    sellerDiscounts: [],
    areaquery: '',
    area: [],
    addressSelected: null,
    courierSelected: 'jne',
    dialogAddress: {
      show: false,
      data: null
    },
    dialogOrigin: {
      show: false,
      data: null
    },
    MyAddress: [],
    SellerBanks: [],
    shippingList: [],
    shippingSelected: 0,
    paymentSelected: null,
    checkoutNotes: '',
    PackageDiscount: 0,
    BookingCode: '',
    BookingCode2: '',
    ShippingMethod: 0,
    WithoutAddressSelectedKecamatan: 0,
    PaidAmount: 0,
    PaidDeposit: 0,
    UserDeposit: 0,
    ShippingCustom: 0,
    itemsCouriers: [
      { value: 'jne', name: 'JNE', show: true },
      { value: 'ninja', name: 'Ninja', show: true },
      { value: 'jnt', name: 'J&T', show: true },
      { value: 'pos', name: 'POS', show: true },
      { value: 'sicepat', name: 'SICEPAT', show: true },
      { value: 'ide', name: 'ID Express', show: true },
      { value: 'cod', name: 'COD / PICKUP', show: true }
    ],
    originList: [],
    originSelected: 0,
    trx_source: '',
    trx_admin: 6,
    discountGlobalCheckout: {
      percent: 0,
      value: 0
    },
    discountCustomerCheckout: {
      percent: 0,
      value: 0
    },
    discountPackageCheckout: {
      percent: 0,
      value: 0
    },
    priceDiscountTreatCustomer: 0,
    VendorSelected: 0,
    StockOutReason: 0,
    dialogVendor: {
      show: false,
      data: null
    },
    TRX_REFID: '',
    TRX_REFID_DATE: vm.$localDT(new Date(), 'datedefault'),
    TRX_REFID_DATA: []
  }),
  mounted () {
    this.setCart(this.cartData)
    this.reloadDiscount(this.cartView)
    this.SyncShipping()
    this.originSelected = this.$store.state.user.district
    this.TRX_REFID_DATE = this.$localDT(new Date(), 'datedefault')
    this.SET_REFID()
  },
  computed: {
    GetCartAdditional () {
      return this.cart.filter(r => parseInt(r.is_additional))
    },
    GetCartMerchandise () {
      return this.cart.filter(r => parseInt(r.is_merchandise))
    },
    GetCart () {
      return this.cart.filter(r => !parseInt(r.is_merchandise) && !parseInt(r.is_additional))
    },
    GetDiscount () {
      return this.$Cart(this.sellerDiscounts, this.cartSubTotal, this.cartSubTotalLabelPrice)
    },
    computedDateFormatted () {
      return this.formatDate(this.createdDate)
    },
    CountTotal () {
      if (this.discountGlobalCheckout.value || this.ShippingCustom) {
        return ((this.cartSubTotal - (this.discountGlobalCheckout.value || 0)) + this.ShippingCustom)
      } else {
        return (this.cartSubTotal - (this.discountGlobalCheckout.value || 0))
      }
    },
    ...mapState({
      cartData: state => state.cart,
      cartSubTotal: function (state) {
        let subtotal = 0
        const cartOnly = this.GetCart
        for (let x = 0; x < cartOnly.length; x++) {
          subtotal += (parseInt(cartOnly[x].price_discount) * cartOnly[x].quantity)
          // if (this.cartView === 0 && this.cartBuyerSelected) {
          //   subtotal += (this.$BuyPrice(cartOnly[x], this.cartBuyerSelected.role) * cartOnly[x].quantity)
          // } else {
          //   subtotal += (this.$BuyPrice(cartOnly[x], state.user.role) * cartOnly[x].quantity)
          // }
        }
        if (this.PackageDiscount >= subtotal) {
          this.PackageDiscount = subtotal
        }
        return subtotal - (this.PackageDiscount || 0)
      },
      cartSubTotalLabelPrice: function (state) {
        const cartOnly = this.GetCart
        let subtotal = 0
        for (let x = 0; x < cartOnly.length; x++) {
          subtotal += (parseInt(cartOnly[x].price_discount) * cartOnly[x].quantity)
        }
        return subtotal
      },
      countShippingCost: function () {
        const s = this.shippingList.find(r => parseInt(r.id) === parseInt(this.shippingSelected))
        if (s && this.courierSelected && this.courierSelected !== 'cod') {
          return parseInt(s.cost_value) * parseInt(this.$totalWeight(this.cart).toFixed(0))
        } else {
          return 0
        }
      }
    })
  },
  watch: {
    TRX_REFID (v) {
      // console.log(v)
    },
    priceDiscountTreatCustomer (v) {
      this.CountPaidAmount()
      // if (this.CountTotal > parseInt(v)) {
      //   this.priceDiscountTreatCustomer = this.CountTotal
      // } else if (!v) {
      //   this.priceDiscountTreatCustomer = 0
      // }
    },
    shippingSelected () {
      this.ShippingCustom = 0
      const s = this.shippingList.find(r => parseInt(r.id) === parseInt(this.shippingSelected))
      if (s && this.courierSelected && this.courierSelected !== 'cod') {
        this.ShippingCustom = parseInt(s.cost_value) * parseInt(this.$totalWeight(this.cart).toFixed(0))
      }
    },
    'isDropship.show': function (v) {
      if (v) {
        if (this.cartBuyerSelected) {
          const c = this.cartBuyerSelected
          this.isDropship.dropship_name = c.name ? c.name + (c.instagram ? ' (@' + c.instagram + ')' : '') : ''
          this.isDropship.dropship_phone = c.phone ? '0' + c.phone : ''
        }
        if (this.cartView === 1) {
          this.isDropship.dropship_name = this.$store.state.user.name
          this.isDropship.dropship_phone = this.$store.state.user.phone ? '0' + this.$store.state.user.phone : ''
        }
      }
    },
    createdDate (val) {
      this.dateFormatted = this.formatDate(this.createdDate)
    },
    cartView: function (v) {
      this.VendorSelected = 0
      this.StockOutReason = 0
      this.PackageDiscount = 0
      this.$store.dispatch('VENDOR_GET')
      this.reloadDiscount(v)
      this.SyncShipping()
      this.isDropship.show = false
      this.isDropship.dropship_name = ''
      this.isDropship.dropship_phone = ''
      this.isDropship.dropship_notes = ''
      if (v === 1) {
        this.isDropship.dropship_name = this.$store.state.user.name
        this.isDropship.dropship_phone = this.$store.state.user.phone ? '0' + this.$store.state.user.phone : ''
      }
    },
    VendorSelected (v) {
      this.$emit('vendor-selected', v)
    },
    cartBuyerSelected: function (v) {
      if (!this.cartView) {
        this.trx_admin = 6
      }
      if (v) {
        this.isDropship.dropship_name = v.name ? v.name + (v.instagram ? ' (@' + v.instagram + ')' : '') : ''
        this.isDropship.dropship_phone = v.phone ? '0' + v.phone : ''
        this.LoadUserDeposit(v.id)
      }
      if (this.cartView === 1) {
        this.isDropship.dropship_name = this.$store.state.user.name
        this.isDropship.dropship_phone = this.$store.state.user.phone ? '0' + this.$store.state.user.phone : ''
      }
      this.reloadDiscount(this.cartView)
      this.SyncShipping()
      this.SET_REFID()
    },
    cartSyncType (v) {
      this.VendorSelected = 0
      this.StockOutReason = 0
      this.$store.dispatch('VENDOR_GET')
    },
    ShippingMethod () {
      if (this.courierSelected !== 'cod') {
        this.shippingList = []
        this.loadShipping()
      } else {
        this.shippingSelected = 0
      }
    },
    WithoutAddressSelectedKecamatan: function (kecamatanid) {
      if (parseInt(kecamatanid) && this.courierSelected !== 'cod') {
        this.shippingList = []
        this.loadShipping()
      } else {
        this.shippingSelected = 0
      }
    },
    addressSelected: function (newData) {
      if (newData && this.courierSelected !== 'cod') {
        this.shippingList = []
        this.loadShipping()
      } else {
        this.shippingSelected = 0
      }
    },
    originSelected: function (newData, oldData) {
      this.loadShipping()
      // const getOrigin = this.originList.find(r => parseInt(r.value) === parseInt(newData))
      // if (getOrigin && this.addressSelected) {
      //   if (parseInt(getOrigin.status) !== 1) {
      //     setTimeout(() => {
      //       this.$store.commit('SNACKBAR', { show: true, text: 'Shipping origin isn\'t active!' })
      //     }, 100)
      //   } else {
      //   }
      // } else {
      //   this.loadShipping()
      // }
    },
    courierSelected: function (newData) {
      if (newData && this.courierSelected !== 'cod') {
        this.loadShipping()
      } else {
        this.shippingSelected = 0
      }
    },
    areaquery: function (v) {
      if (!v) {
        return
      }
      const findSelectedArea = this.area.find(r => r.area_name === v)
      if (findSelectedArea) {
        return
      }
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(() => {
        this.areaGet('?q=' + v)
        clearTimeout(searchTimeout)
      }, 300)
    },
    cartData: function (carts) {
      this.setCart(carts)
    },
    step: function (v) {
      if (v === 2) {
        /* this.BookingCode = ''
        this.BookingCode2 = '' */
        this.loadAddress()
        let user = 0
        if (this.cartView === 0 && this.cartBuyerSelected) {
          user = parseInt(this.$store.state.user.id)
        }
        this.$store.dispatch('BANK_SELLER_GET', user).then((res) => {
          if (res.status) {
            this.SellerBanks = res.data.data || []
          } else {
            this.SellerBanks = []
          }
        })
        this.CountPaidAmount()
      }
    },
    ShippingCustom () {
      this.CountPaidAmount()
    }
  },
  methods: {
    async SET_REFID () {
      if (this.trx_source) {
        this.TRX_REFID_DATA = []
        let GETLASTID = 0
        const X = await this.LAST_REFID_GET('/' + (this.trx_source || '').replace('/', '-') + '/' + (parseInt(this.trx_admin) || '0') + '/' + parseInt(this.$store.state.user.id) + '/' + this.TRX_REFID_DATE)
        if (X) {
          if (X.status) {
            GETLASTID = parseInt(X.data.data.total)
            this.TRX_REFID_DATA = X.data.data.data || []
          }
        }
        const result = this.trx_source + '/' + ('ADM' + (parseInt(this.trx_admin) || '0') + '/') + ('EB' + parseInt(this.$store.state.user.id)) + '/' + this.GEN_REFID_DATE() + '/' + this.GET_REFID_INCREMENT(GETLASTID)
        this.TRX_REFID = result
      }
    },
    async LAST_REFID_GET (ref) {
      return await this.$store.dispatch('LAST_REFID_GET', ref)
        .then(res => res)
        .catch(err => err)
    },
    GEN_REFID_DATE () {
      const date = this.TRX_REFID_DATE
      this.TRX_REFID_DATE = date
      const [year, month, day] = date.split('-')
      const y = year ? year.substr(2, 2) : 'XX'
      return `${day}${month}${y}`
    },
    GET_REFID_INCREMENT (n) {
      n = n + 1
      if (n < 10) {
        return '000' + n
      } else if (n < 100) {
        return '00' + n
      } else if (n < 1000) {
        return '0' + n
      } else {
        return n
      }
    },
    REFID_PARSER () {
      const refid = this.TRX_REFID
      if (refid) {
        const refidSplit = refid.split('/')
        if (refidSplit.length === 5) {
          const d = refidSplit[3]
          const dt = d.substr(4, 2) + '-' + d.substr(2, 2) + '-' + d.substr(0, 2)
          const result = {
            trx_source: refidSplit[0] || '',
            uid: parseInt(refidSplit[1].replace('ADM', '')) || 0,
            user: parseInt(refidSplit[2].replace('EB', '')),
            seller: parseInt(refidSplit[2].replace('EB', '')),
            created: '20' + dt
          }
          return result
        } else if (refidSplit.length === 6) {
          const d = refidSplit[4]
          const dt = d.substr(4, 2) + '-' + d.substr(2, 2) + '-' + d.substr(0, 2)
          const result = {
            trx_source: (refidSplit[0] + refidSplit[1]) || '',
            uid: parseInt(refidSplit[2].replace('ADM', '')) || 0,
            user: parseInt(refidSplit[3].replace('EB', '')),
            seller: parseInt(refidSplit[4].replace('EB', '')),
            created: '20' + dt
          }
          return result
        }
      }
      return null
    },
    VENDOR_OPEN () {
      this.dialogVendor.data = Object.assign({}, {
        id: null,
        name: '',
        phone: '',
        address: ''
      })
      if (this.VendorSelected) {
        const f = this.$store.state.vendor.data.find(r => parseInt(r.id) === parseInt(this.VendorSelected))
        if (f) {
          this.dialogVendor.data = Object.assign({}, f)
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Undefined vendor!' })
          return false
        }
      }
      this.dialogVendor.show = true
    },
    VENDOR_PROCESS () {
      const newVendor = Object.assign({}, this.dialogVendor.data)
      if (!newVendor.name) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Input valid name!' })
        return
      }
      if (!this.$validatePhone(newVendor.phone)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Input valid phone number!' })
        return
      }
      if (!newVendor.address) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Input valid address' })
        return
      }
      this.$store.dispatch('VENDOR_PROCESS', newVendor).then((res) => {
        if (res.status) {
          this.$store.commit('SNACKBAR', { show: true, text: newVendor.id ? 'Vendor updated!' : 'Vendor Created!' })
          this.$store.dispatch('VENDOR_GET')
          this.dialogVendor.show = false
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
        }
      })
    },
    ResetPrice (c, index) {
      if (this.cartBuyerSelected) {
        const role = this.cartBuyerSelected.role === 'reseller' || this.cartBuyerSelected.role === 'customer' ? this.cartBuyerSelected.role : 'customer'
        const discountPrice = parseInt(c['price_' + role + '_lead']) || 0
        if (discountPrice) {
          this.GetCart[index].price_discount = discountPrice
          this.GetCart[index].customDiscount = 100 - parseFloat(parseFloat((discountPrice * 100) / parseInt(c.label_price)).toFixed(1))
        }
      }
    },
    updatedCartMerchandiseAdditionalQuantity (id, quantity) {
      this.$store.dispatch('CART_UPDATE', {
        id: id,
        quantity: quantity
      }).then((res) => {
        // console.log(res)
        // UPDATED
      })
    },
    SetCustomDiscountItem (index, id, percent) {
      const price = parseInt(this.GetCart[index].label_price)
      if (percent) {
        let c = parseFloat(this.GetCart[index].customDiscount) || 0
        if (c > 99) {
          c = 100
        } else if (!c) {
          c = 0
        }
        this.GetCart[index].customDiscount = parseFloat(parseFloat(c).toFixed(1))
        this.GetCart[index].price_discount = price - ((c / 100) * price)
      } else {
        let priceDiscount = parseInt(this.GetCart[index].price_discount)
        if (priceDiscount > price) {
          priceDiscount = price
          this.GetCart[index].price_discount = priceDiscount
        } else if (!priceDiscount) {
          priceDiscount = 0
          this.GetCart[index].price_discount = priceDiscount
        }
        const toPercent = (priceDiscount * 100) / price
        this.GetCart[index].customDiscount = 100 - parseFloat(parseFloat(toPercent).toFixed(1))
      }

      this.$store.dispatch('CART_UPDATE', {
        id: id,
        price: price,
        price_discount: this.GetCart[index].price_discount
      }).then((res) => {
        // console.log(res)
        // UPDATED
      })

      this.discountGlobalCheckout.percent = this.GetDiscount.badge || 0
      this.discountGlobalCheckout.value = this.GetDiscount.value

      this.CountPaidAmount()
    },
    SetCustomDiscountGlobal (percent) {
      const price = parseInt(this.cartSubTotal)
      if (percent) {
        let percent = parseFloat(parseFloat(this.discountGlobalCheckout.percent).toFixed(1))
        if (percent > 100) {
          percent = 100
          this.discountGlobalCheckout.percent = percent
        } else if (!percent) {
          percent = 0
          this.discountGlobalCheckout.percent = percent
        }
        this.discountGlobalCheckout.value = ((percent / 100) * price)
      } else {
        let priceDiscount = parseInt(this.discountGlobalCheckout.value)
        if (priceDiscount > price) {
          priceDiscount = price
          this.discountGlobalCheckout.value = priceDiscount
        } else if (!priceDiscount) {
          priceDiscount = 0
          this.discountGlobalCheckout.value = priceDiscount
        }
        const toPercent = (priceDiscount * 100) / price
        this.discountGlobalCheckout.percent = parseFloat(parseFloat(toPercent).toFixed(1))
      }
      this.CountPaidAmount()
    },
    SetCustomDiscountCustomer (percent) {
      const price = parseInt(this.cartSubTotal)
      if (percent) {
        let percent = parseFloat(parseFloat(this.discountCustomerCheckout.percent).toFixed(1))
        if (percent > 100) {
          percent = 100
          this.discountGlobalCheckout.percent = percent
        } else if (!percent) {
          percent = 0
          this.discountGlobalCheckout.percent = percent
        }
        this.discountGlobalCheckout.value = ((percent / 100) * price)
      } else {
        let priceDiscount = parseInt(this.discountCustomerCheckout.value)
        if (priceDiscount > price) {
          priceDiscount = price
          this.discountCustomerCheckout.value = priceDiscount
        } else if (!priceDiscount) {
          priceDiscount = 0
          this.discountCustomerCheckout.value = priceDiscount
        }
        const toPercent = (priceDiscount * 100) / price
        this.discountCustomerCheckout.percent = parseFloat(parseFloat(toPercent).toFixed(1))
      }
      this.CountPaidAmount()
    },
    SetCustomDiscountPackage (percent) {
      const price = parseInt(this.cartSubTotal)
      if (percent) {
        let percent = parseFloat(parseFloat(this.discountPackageCheckout.percent).toFixed(1))
        if (percent > 100) {
          percent = 100
          this.discountGlobalCheckout.percent = percent
        } else if (!percent) {
          percent = 0
          this.discountGlobalCheckout.percent = percent
        }
        this.discountGlobalCheckout.value = ((percent / 100) * price)
      } else {
        let priceDiscount = parseInt(this.discountPackageCheckout.value)
        if (priceDiscount > price) {
          priceDiscount = price
          this.discountPackageCheckout.value = priceDiscount
        } else if (!priceDiscount) {
          priceDiscount = 0
          this.discountPackageCheckout.value = priceDiscount
        }
        const toPercent = (priceDiscount * 100) / price
        this.discountPackageCheckout.percent = parseFloat(parseFloat(toPercent).toFixed(1))
      }
      this.CountPaidAmount()
    },
    dropAllow (e) {
      e.preventDefault()
    },
    drop (e) {
      const data = e.dataTransfer.getData('product-id')
      const dataSplit = data.split(',')
      let productID = 0
      let isPackage = 0
      if (dataSplit.length === 2) {
        productID = parseInt(dataSplit[0]) || 0
        isPackage = parseInt(dataSplit[1]) || 0
      }
      if (!isPackage) {
        this.CART_MERCHANDISE_ADDITIONAL_PROCESS('merchandise', productID)
      } else {
        this.$store.commit('SNACKBAR', { show: true, text: 'Package cannot be added to merchandise!' })
      }
    },
    CountPaidAmount () {
      this.PaidAmount = this.CountTotal - parseInt(this.priceDiscountTreatCustomer) - (parseInt(this.discountCustomerCheckout.value) || 0) - (parseInt(this.discountPackageCheckout.value || 0))
      /*  if (parseInt(this.UserDeposit) > 0) {
          if (parseInt(this.UserDeposit) > parseInt(this.PaidAmount)) {
            this.PaidDeposit = parseInt(this.PaidAmount) - (parseInt(this.discountCustomerCheckout.value) || 0) - (parseInt(this.discountPackageCheckout.value || 0) - (parseInt(this.priceDiscountTreatCustomer)) || 0) - parseInt(this.priceDiscountTreatCustomer) || 0
            this.PaidAmount = parseInt(this.PaidAmount) - (parseInt(this.discountCustomerCheckout.value) || 0) - (parseInt(this.discountPackageCheckout.value || 0) - (parseInt(this.priceDiscountTreatCustomer)) || 0) - parseInt(this.priceDiscountTreatCustomer) || 0
          } else {
            this.PaidDeposit = parseInt(this.UserDeposit)
            this.PaidAmount = parseInt(this.PaidAmount) - parseInt(this.UserDeposit)
          }
        } */
    },
    LoadUserDeposit (user) {
      this.$store.dispatch('BALANCE', user)
        .then((res) => {
          if (res.status) {
            this.UserDeposit = isNaN(parseInt(res.data.data)) ? 0 : parseInt(res.data.data) || 0
            this.PaidDeposit = this.UserDeposit
          }
        })
    },
    LoadOrigin () {
      this.originList = []
      let originID = parseInt(this.$store.state.user.lead_origin) // kecamatan_id
      let originCityName = this.$store.state.user.lead_city_name
      if (this.cartView === 0 && this.cartBuyerSelected) {
        originID = parseInt(this.$store.state.user.district)
        originCityName = this.$store.state.user.city_name
      }
      this.originList.push({
        id: null,
        value: originID,
        name: originCityName + ' (default)',
        status: 1
      })
      this.originSelected = parseInt(originID) || 0
      this.$store.dispatch('ORIGIN_GET').then((res) => {
        if (res.status) {
          res.data.data.map((r) => {
            this.originList.push({
              id: r.id,
              value: r.kecamatan_id,
              name: r.kecamatan_name + ' (' + r.city_name + ') ' + (parseInt(r.status) === 0 ? '#NOT-ACTIVE' : ''),
              status: parseInt(r.status) || 0
            })
          })
        }
      })
    },
    SyncShipping () {
      this.originSelected = 0
      this.itemsCouriers.map(r => {
        r.show = true
        return r
      })
      this.courierSelected = ''
      this.shippingList = []
      if (this.cartView === 0) {
        const f = this.$store.state.user.couriers
        this.itemsCouriers.map(r => {
          const rgx = new RegExp(r.value, 'gi')
          r.show = f ? f.match(rgx) : true
          return r
        })
        this.courierSelected = this.itemsCouriers.length ? this.itemsCouriers[0].value : ''
        if (this.$store.state.user.role === 'pusat') {
          this.LoadOrigin()
        }
      } else if (this.cartView === 1) {
        const f = this.$store.state.user.lead_couriers
        this.itemsCouriers.map(r => {
          const rgx = new RegExp(r.value, 'gi')
          r.show = f ? f.match(rgx) : true
          return r
        })
        this.courierSelected = this.itemsCouriers.length ? this.itemsCouriers[0].value : ''
        if (this.$store.state.user.lead_role === 'pusat') {
          this.LoadOrigin()
        }
      }
      this.CountPaidAmount()
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    loadPricingSeller (buyerID) {
      this.$store.dispatch('GLOBAL_PRICING_SELLER_GET', buyerID).then((res) => {
        if (res.status) {
          this.sellerDiscounts = res.data.data || []
        } else {
          this.sellerDiscounts = []
        }
      })
    },
    reloadDiscount (v) {
      this.step = 1
      this.addressSelected = 0
      this.shippingSelected = 0
      if (v === 0 && this.cartBuyerSelected) {
        this.loadPricingSeller(parseInt(this.cartBuyerSelected.id))
      } else {
        this.loadPricingSeller(parseInt(this.$store.state.user.id))
      }
    },
    CHECKOUT () {
      if (!this.trx_source) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please select order source!' })
        return false
      }
      // else if (this.trx_source === 'WA' && !parseInt(this.trx_admin)) {
      //   this.$store.commit('SNACKBAR', { show: true, text: 'Please select admin source!' })
      //   return false
      // }
      if (this.loadingShipping) {
        return false
      }
      if (!this.addressSelected && parseInt(this.ShippingMethod) === 1) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please select address' })
        return false
      } else if (!parseInt(this.WithoutAddressSelectedKecamatan) && parseInt(this.ShippingMethod) === 0) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please select area' })
        return false
      }
      if (!parseInt(this.shippingSelected) && this.courierSelected !== 'cod') {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please select shipping' })
        return false
      }
      const getOrigin = this.originList.find(r => parseInt(r.value) === parseInt(this.originSelected))
      if (getOrigin && this.$store.state.user.role === 'pusat') {
        if (parseInt(getOrigin.status) !== 1) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Shipping origin isn\'t active!' })
          return false
        }
      }
      if (!this.paymentSelected) {
        if (this.discountGlobalCheckout.value || this.ShippingCustom) {
          if (parseInt(((this.cartSubTotal - (this.discountGlobalCheckout.value || 0)) + this.ShippingCustom))) {
            this.$store.commit('SNACKBAR', { show: true, text: 'Please select payment bank' })
            return false
          }
        } else if (parseInt(this.cartSubTotal - (this.discountGlobalCheckout.value || 0))) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Please select payment bank' })
          return false
        }
      }
      if (this.isDropship.show) {
        if (!this.isDropship.dropship_name) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Please input dropship name' })
          return false
        }
        if (!this.isDropship.dropship_phone) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Please input dropship phone' })
          return false
        }
      }
      if (!confirm('Continue Checkout ?')) {
        return false
      }
      let addr = ''
      if (parseInt(this.ShippingMethod)) {
        addr = this.addressSelected.name + '||' + this.addressSelected.phone + '||' + this.addressSelected.address + '||' + this.addressSelected.province_name
      } else {
        const d = this.area.find(r => (parseInt(r.kecamatan_id) === parseInt(this.WithoutAddressSelectedKecamatan)))
        if (d) {
          addr = '### ' + d.area_name
        }
      }
      const prepareTrx = {
        id: null,
        refid: this.TRX_REFID && this.TRX_REFID.split('/').length === 5 ? this.TRX_REFID.split('/')[0] + '/' + this.TRX_REFID.split('/')[3] + '/' + this.TRX_REFID.split('/')[4] : this.TRX_REFID && this.TRX_REFID.split('/').length === 6 ? this.TRX_REFID.split('/')[0] + '/' + this.TRX_REFID.split('/')[1] + '/' + this.TRX_REFID.split('/')[4] + '/' + this.TRX_REFID.split('/')[5] : '',
        trx_source: this.trx_source || '',
        uid: parseInt(this.$store.state.user.uid) || parseInt(this.trx_admin) || 0,
        shipping_address: addr,
        shipping_courier: this.courierSelected || '',
        shipping_cost_id: parseInt(this.shippingSelected),
        shipping_weight: this.$totalWeight(this.cart),
        shipping_total: this.countShippingCost,
        payment_bank: this.paymentSelected ? (this.paymentSelected.bank_name + ',' + this.paymentSelected.bank_account_name + ',' + this.paymentSelected.bank_account_number) : '',
        seller_vendor: 0,
        buyer_none_reason: 0,
        dropship_name: this.isDropship.show && this.isDropship.dropship_name ? this.isDropship.dropship_name : '',
        dropship_phone: this.isDropship.show && this.isDropship.dropship_phone ? this.isDropship.dropship_phone : '',
        dropship_notes: this.isDropship.show && this.isDropship.dropship_notes ? this.isDropship.dropship_notes : '',
        platform: '',
        booking_code: (this.BookingCode || '') + '|-|' + (this.BookingCode2 || ''),
        discount_package: this.discountPackageCheckout.value || 0,
        notes: this.checkoutNotes || '',
        status: 0,
        ShippingCustom: parseInt(this.ShippingCustom) || 0,
        PaidDeposit: parseInt(this.PaidDeposit) || 0,
        PaidAmount: parseInt(this.PaidAmount) || 0,
        discountGlobalCheckout: this.discountCustomerCheckout.value > 0 ? this.discountCustomerCheckout.value : this.discountGlobalCheckout.value,
        discountCarts: this.GetCart,
        price_discount_treat: this.priceDiscountTreatCustomer
      }
      const getDateFromRefid = this.REFID_PARSER
      if (getDateFromRefid) {
        prepareTrx.created = getDateFromRefid().created || null
      }
      if (parseInt(this.cartView) === 0 && this.cartBuyerSelected) {
        prepareTrx.seller = parseInt(this.$store.state.user.id)
        prepareTrx.buyer = parseInt(this.cartBuyerSelected.id)
      } else if (parseInt(this.cartView) === 1) {
        prepareTrx.seller = parseInt(this.$store.state.user.lead)
        prepareTrx.buyer = parseInt(this.$store.state.user.id)
      } else {
        this.$store.commit('SNACKBAR', { show: true, text: 'Invalid action' })
        return false
      }
      this.$store.dispatch('CHECKOUT', prepareTrx).then(res => {
        if (res.status) {
          this.priceDiscountTreatCustomer = 0
          if (this.$store.state.screenWidth < 414) {
            this.$emit('close-dialog', true)
          }
          this.trx_source = ''
          this.trx_admin = 0
          this.$emit('reload', true)
          this.$emit('reload-product', true)
          this.step = 1
          this.$store.commit('SNACKBAR', { show: true, text: 'New order created' })
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
        }
      })
    },
    CheckoutSyncInventory () {
      if (!this.cartSyncType && !parseInt(this.VendorSelected)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please select vendor!' })
        return false
      }
      if (!confirm((this.cartSyncType === 0 ? 'Process Buy?' : 'Process Moving Stock ?'))) {
        return false
      }
      const prepareTrx = {
        id: null,
        trx_source: '',
        shipping_address: this.addressSelected ? (this.addressSelected.name + '||' + this.addressSelected.phone + '||' + this.addressSelected.address + '||' + this.addressSelected.province_name) : '',
        shipping_courier: this.courierSelected || '',
        shipping_cost_id: this.shippingSelected ? parseInt(this.shippingSelected) : 0,
        shipping_weight: this.$totalWeight(this.cart),
        shipping_total: this.ShippingCustom,
        seller_vendor: this.VendorSelected || 0,
        buyer_none_reason: this.StockOutReason || 0,
        platform: '',
        notes: this.checkoutNotes || '',
        status: 1,
        sync: parseInt(this.cartSync)
      }
      prepareTrx.created = this.createdDate
      this.$store.dispatch('TRX_SYNC', {
        stokist: this.cartBuyerSelected ? this.cartBuyerSelected.id : 0,
        data: prepareTrx
      }).then((res) => {
        if (res.status) {
          this.$emit('reload', true)
          this.$emit('reload-product', true)
          this.step = 1
          this.$store.commit('SNACKBAR', { show: true, text: !this.cartSyncType ? 'Buy Success' : 'Stock Moving Created' })
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
        }
      })
    },
    loadShipping () {
      this.loadingShipping = true
      let origin = parseInt(this.$store.state.user.lead_origin)
      if (this.cartBuyerSelected) {
        origin = parseInt(this.$store.state.user.district)
      }
      const s = this.itemsCouriers.filter(r => r.show)
      if (!s.find(r => r.value === this.courierSelected)) {
        if (s.length) {
          this.courierSelected = s[0].value
        } else {
          this.courierSelected = ''
          this.shippingSelected = 0
          return false
        }
      }
      const sss = parseInt(this.ShippingMethod) === 1 ? parseInt(this.addressSelected.kecamatan_id) : parseInt(this.WithoutAddressSelectedKecamatan)
      if ((parseInt(this.originSelected) || origin) && sss) {
        this.$store.dispatch('SHIPPING_CHECK', {
          kecamatan_from: parseInt(this.originSelected) || origin,
          kecamatan_to: sss,
          courier: this.courierSelected || 'jne'
        }).then((res) => {
          this.loadingShipping = false
          if (res.status) {
            if (res.data.data.resource === 'external') {
              this.loadShipping()
            } else {
              this.shippingList = res.data.data.data || []
              if (this.shippingList.length) {
                this.shippingSelected = parseInt(this.shippingList[0].id)
              }
              this.CountPaidAmount()
            }
          }
        })
      }
    },
    loadAddress () {
      let user = 0
      if (this.cartView === 0 && this.cartBuyerSelected) {
        user = this.cartBuyerSelected.id
      }
      this.MyAddress = []
      this.$store.dispatch('ADDRESS_GET', user).then((res) => {
        if (res.status) {
          this.MyAddress = res.data.data || []
        } else {
          this.MyAddress = []
        }
      })
    },
    areaGet (q, id, kecamatanID) {
      this.$store.dispatch('AREA_GET', kecamatanID ? '?kecamatan_id=' + kecamatanID : q + (parseInt(id) ? '&id=' + id : '')).then(res => {
        if (res.status) {
          this.area = res.data.data || []
        }
      })
    },
    setCart (carts) {
      this.cart = []
      for (let c = 0; c < carts.length; c++) {
        const cc = Object.assign({}, carts[c])
        const priceDiscountDefault = this.cartBuyerSelected ? (parseInt(this.$BuyPrice(cc, this.cartBuyerSelected.role)) || parseInt(cc.label_price)) : parseInt(cc.label_price)
        const priceDiscount = cc.price ? parseInt(cc.price_discount) : priceDiscountDefault
        if (priceDiscount < parseInt(cc.label_price)) {
          cc.customDiscount = parseFloat(parseFloat(100 - ((priceDiscount * 100) / parseInt(cc.label_price))).toFixed(1))
        } else {
          cc.customDiscount = 0
        }
        if (cc.customDiscount > 100) {
          cc.customDiscount = 100
        } else if (!cc.customDiscount) {
          cc.customDiscount = 0
        }
        cc.price_discount = cc.label_price - ((cc.customDiscount / 100) * cc.label_price)
        this.cart.push(cc)
      }
      this.discountGlobalCheckout.percent = this.GetDiscount.badge || 0
      this.discountGlobalCheckout.value = this.GetDiscount.value
    },
    CART_MERCHANDISE_ADDITIONAL_PROCESS (type, product) {
      if (product) {
        if (!parseInt(product.id)) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Invalid Product Selected!' })
          return false
        }
      } else {
        return false
      }
      const newCart = {
        id: null,
        product: product.id,
        trx: 0,
        user: parseInt(this.$store.state.user.id),
        seller: parseInt(this.$store.state.user.id),
        price_buy: parseInt(product.price_hpp) || 0,
        weight: parseInt(product.weight) || 0,
        buyer: 0,
        quantity: 1,
        price: 0,
        price_discount: 0,
        sync: 0,
        is_merchandise: type === 'merchandise' ? 1 : 0,
        is_additional: type === 'additional' ? 1 : 0,
        status: 1
      }
      this.$store.dispatch('CART_ADD', newCart).then((res) => {
        if (res.status) {
          this.$emit('reload', true)
        } else {
          this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
        }
      })
    },
    CART_UPDATE (act, cart) {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      const updatedCart = Object.assign({}, cart)
      if (act === 'quantity') {
        if (parseInt(updatedCart.quantity)) {
          this.$emit('update', updatedCart)
        } else {
          return true
        }
        return true
      } else if (act === 'minus') {
        updatedCart.quantity = parseInt(updatedCart.quantity) - 1
        cart.quantity = updatedCart.quantity
      } else if (act === 'plus') {
        updatedCart.quantity = parseInt(updatedCart.quantity) + 1
        cart.quantity = updatedCart.quantity
      }
      searchTimeout = setTimeout(() => {
        if (act === 'minus') {
          this.$emit('update', updatedCart)
        } else if (act === 'plus') {
          this.$emit('update', updatedCart)
        } else if (act === 'delete') {
          this.$store.dispatch('CART_DELETE', updatedCart.id).then((res) => {
            this.$emit('reload', true)
          })
        }
        clearTimeout(searchTimeout)
      }, 300)
    },
    OpenAddress (address) {
      if (address) {
        this.dialogAddress.data = Object.assign({}, address)
        this.areaGet('?q=', this.dialogAddress.data.area)
      } else {
        let user = this.$store.state.user.id
        if (this.cartView === 0 && this.cartBuyerSelected) {
          user = this.cartBuyerSelected.id
        }
        this.dialogAddress.data = {
          id: 0,
          user: user,
          name: '',
          phone: '',
          address: '',
          area: 0
        }
      }
      this.dialogAddress.show = true
    },
    ProcessAddress () {
      const newAddress = Object.assign({}, this.dialogAddress.data)
      if (!newAddress.name) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Input valid name' })
        return
      }
      if (!this.$validatePhone(newAddress.phone)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Input valid phone number' })
        return
      }
      if (!newAddress.address) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Input valid address' })
        return
      }
      if (!parseInt(newAddress.area)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please select area' })
        return
      }
      if (parseInt(newAddress.id)) {
        this.$store.dispatch('ADDRESS_UPDATE', newAddress).then((res) => {
          if (res.status) {
            this.loadAddress()
            this.dialogAddress.show = false
            this.addressSelected = Object.assign({}, res.data.data)
            this.$store.commit('SNACKBAR', { show: true, text: 'Address updated' })
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
        })
      } else {
        this.$store.dispatch('ADDRESS_ADD', newAddress).then((res) => {
          if (res.status) {
            this.loadAddress()
            this.dialogAddress.show = false
            this.$store.commit('SNACKBAR', { show: true, text: 'Address created' })
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
        })
      }
    },
    OpenOrigin (o) {
      const origin = this.originList.find(r => parseInt(r.value) === parseInt(o))
      if (origin) {
        // if (!origin.id) {
        //   this.$store.commit('SNACKBAR', { show: true, text: 'Cannot update default setting' })
        //   return false
        // }
        this.dialogOrigin.data = Object.assign(dialogOrigin, {
          id: origin.id,
          kecamatan_id: origin.id ? o : 0,
          status: parseInt(origin.status) || 0
        })
        if (origin.id) {
          this.areaGet('?q=', o, o)
        }
      } else {
        this.dialogOrigin.data = Object.assign({}, dialogOrigin)
      }
      this.dialogOrigin.show = true
    },
    ProcessOrigin () {
      const newArea = this.area.find(r => parseInt(r.kecamatan_id) === parseInt(this.dialogOrigin.data.kecamatan_id))
      if (parseInt(this.dialogOrigin.data.status) === 100) {
        if (!confirm('Are you sure want to remove this origin ?')) {
          return false
        }
      } else if (!parseInt(newArea.kecamatan_id)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Please select origin' })
        return
      }
      const newOrigin = Object.assign(dialogOrigin, {
        id: this.dialogOrigin.data.id,
        city_id: newArea.city_id,
        city_type: newArea.city_type,
        city_name: newArea.city_name,
        kecamatan_id: newArea.kecamatan_id,
        kecamatan_name: newArea.kecamatan_name,
        status_message: '',
        url: '',
        latlong: '',
        status: this.dialogOrigin.data.status
      })
      if (parseInt(newOrigin.id)) {
        this.$store.dispatch('ORIGIN_UPDATE', newOrigin).then((res) => {
          if (res.status) {
            this.LoadOrigin()
            this.dialogOrigin.show = false
            this.$store.commit('SNACKBAR', { show: true, text: 'Shipping Origin Updated' })
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
        })
      } else {
        this.$store.dispatch('ORIGIN_ADD', newOrigin).then((res) => {
          if (res.status) {
            this.LoadOrigin()
            this.dialogOrigin.show = false
            this.$store.commit('SNACKBAR', { show: true, text: 'Shipping Origin Created' })
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.my-order {
  height: calc(100vh - 258px);
  overflow: hidden;
  .my-order-area {
    background: rgba(250, 219, 162, 0.15)!important;
    height: calc(100% - 200px);
    overflow-y: scroll!important;
    padding-right: 17px!important;
    box-sizing: content-box!important;
    @media only screen and (max-width: 414px) {
      height: calc(100% - 270px)!important;
    }
    &.cart-view-2 {
      &.cart-sync-type-0 {
        height: calc(100% - 125px)!important;
        @media only screen and (max-width: 414px) {
          height: calc(100% - 200px)!important;
        }
      }
      &.cart-sync-type-1 {
        height: calc(100% - 225px)!important;
        @media only screen and (max-width: 414px) {
          height: calc(100% - 300px)!important;
        }
        &.cart-sync-0 {
          &.cart-buyer-null {
            height: calc(100% - 290px)!important;
            @media only screen and (max-width: 414px) {
              height: calc(100% - 365px)!important;
            }
          }
        }
      }
    }
    .my-order-list {
      margin-bottom: 2px;
      &.theme--light {
        .qty-input {
          color: #000!important;
          border: solid 1px #ccc!important;
        }
      }
      .my-order-list-title {
        line-height: 140%;
        flex-direction: column;
        .price {
          display: block;
          width: 100%;
          padding-top: 6px;
          font-size: 11px;
          font-weight: 600;
        }
      }
      .list-quantity {
        display: inline-block;
        .qty-input {
          display: inline-block;
          border: none;
          outline: none;
          text-align: center;
          border: solid 1px #777!important;
          width: 35px;
          color: #fff;
          font-size: 12px;
        }
      }
    }
  }
  .cart-total-price {
    font-size: 16px;
  }
  &.dialog {
    height: 100vh;
    .my-order-area {
      height: calc(100% - 125px);
    }
    .cart-title {
      font-size: 18px;
      padding-bottom: 7px;
    }
    .cart-total-price {
      font-size: 16px;
    }
    .card-row {
      padding: 7px 20px 0px 20px!important;
      .my-order-list-title {
        font-size: 18px!important;
        .price {
          font-size: 14px;
        }
      }
      .v-image {
        width: 85%!important;
        padding-bottom: 85%;
      }
    }
    .card-act {
      * {
        transform: scale(1.15);
      }
    }
  }
}
.shipping-list-select {
  outline: none;
  cursor: pointer;
  font-size: 12px;
  padding: 0px 7px 1px 7px;
  border-radius: 4px;
}
.select-address {
  cursor: pointer;
  &:hover {
    background: rgba(100, 100, 100, .1);
  }
}
</style>
